import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { monochromeMainColors } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/halfPie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'masculino',
		label: 'Masculino',
		value: data.masculino
	});
	values.push({
		id: 'femino',
		label: 'Feminino',
		value: data.feminino
	});
	values.push({
		id: 'outros',
		label: 'Outros',
		value: data.outros
	});
	values.push({
		id: 'nao_informado',
		label: 'Não Informado',
		value: data.nao_informado
	});
	return values;
};

const total = (data) => data.masculino + data.feminino;

const BeneficiariesByGenderChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={[monochromeMainColors[0], monochromeMainColors[4], monochromeMainColors[3], monochromeMainColors[2]]}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default BeneficiariesByGenderChart;
